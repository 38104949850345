import React, { FC } from 'react';
import { ListOfDiagramsProps } from '../types/types';
import Diagram from '../Diagram/Diagram';
import classes from './ListOfDiagrams.module.css';

const ListOfDiagrams: FC<ListOfDiagramsProps> = ({ diagrams }) => {
  const d = diagrams.map((data) => {
    return <Diagram diagram={data} />;
  });
  return <div className={classes.diagramList}>{d}</div>;
};

export default ListOfDiagrams;
