import React, { FC } from 'react';
import classes from './Diagram.module.css';
import { DiagramProps } from '../types/types';

const Diagram: FC<DiagramProps> = ({ diagram }) => {
  const columns = diagram.map((data) => {
    const height = { height: `${data * 4}px` };
    return <div className={classes.column} style={height} />;
  });
  return <div className={classes.diagram}>{columns}</div>;
};

export default Diagram;
