import React from 'react';
import './App.css';
import { DiagramData } from '../types/types';
import ListOfDiagrams from '../ListOfDiagram/ListOfDiagrams';

function App() {
  const diagramsData: DiagramData[] = [
    [100, 20, 10, 10, 84, 14],
    [53, 40, 10, 1, 84, 1],
    [20, 20, 10, 17, 84, 1],
    [20, 20, 37, 15, 84, 1],
  ];
  return (
    <div className="App">
      <h1>Diagrams</h1>
      <ListOfDiagrams diagrams={diagramsData} />
    </div>
  );
}

export default App;
